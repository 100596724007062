function scrollToAnchor(aid) {
    var aTag = $("a[name='" + aid + "']");
    var headerHeight = $(".logo-container").height();
    var scrollToPosition = aTag.offset().top - headerHeight;

    $('html,body').animate({scrollTop: scrollToPosition}, 'slow');
}

$(function() {
    $(".menu-link").click(function() {
        // console.log('click');
        $("#menu").toggleClass("active");
        $(".container").toggleClass("active");
        $(".menu-overlay").fadeToggle();
        $(".menu-burger").fadeToggle();
        $(".menu-cross").fadeToggle();
        $('body').toggleClass('no-scroll');
    });
    $('.back-to-top').click(function(){
        scrollToAnchor('top');
    });

    $('.floating-cart-button').click(function(){
       $('.floating-cart-popup').toggle();
    });
    $('.popup-close').click(function(){
        $('.floating-cart-popup').hide();
    });

    $('.wait-icon').hide()

    $('.click-wait').click(function(){
//        alert('');
        var wait = $(this).attr('data-wait');
        $(wait).show();
    });
});

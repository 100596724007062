/**
 * Simple JS wrapper for a dealer finder.
 * @type {{settings: {map_id: string, listing_id: string, search_id: string, search_button_id: string, brand: string, limit: number, base_url: string, defaultLocation: {lat: number, lng: number}, customIcons: {marker: {icon: string}, home: {icon: string}}, zoom: number, map_top: number, beforeScripts: map.settings.beforeScripts, beforeInit: map.settings.beforeInit, afterInit: map.settings.afterInit}, el: null, geocoder: null, mapMarkers: *, homeMarker: null, initialized: boolean, initMap: map.initMap, options: map.options, ajax: map.ajax, createInfoWindow: map.createInfoWindow, createSidebarEntry: map.createSidebarEntry, isInitialized: map.isInitialized, getSearchByInput: map.getSearchByInput, searchForDealers: map.searchForDealers, searchLocationsNear: map.searchLocationsNear, addMarker: map.addMarker, panToMarker: map.panToMarker, closeInfoWindows: map.closeInfoWindows, scrollToMap: map.scrollToMap, bindFunctions: map.bindFunctions, loadScripts: map.loadScripts, processKey: map.processKey}}
 */
var map = {
    settings: {
        map_id: '#map',
        listing_id: '.dealer-list-holder',
        search_id: '#search',
        search_button_id: '#go-button',
        alert_id: '#alert',
        brand: 'ridgeback',
        limit: 50, // Not used yet.
        base_url: '/ajax/get_markers/',
        defaultLocation: {lat: 52.152029, lng: -0.673256},
        customIcons: {
            marker: {icon: '/assets/images/misc/marker.png'},
            home: {icon: '/assets/images/misc/home.png'}
        },
        zoom: 10,
        map_top: 0,
        beforeScripts: function () {
        },
        beforeInit: function () {
        },
        afterInit: function () {
        },
    },

    el: null,
    geocoder: null,
    mapMarkers: Array(),
    homeMarker: null,
    initialized: false,

    /**
     *
     */
    initMap: function () {
        // console.log(this.settings);
        this.settings.beforeScripts();
        this.loadScripts(function () {
            map.settings.beforeInit();
            map.el = new GMaps({
                div: map.settings.map_id,
                lat: map.settings.defaultLocation.lat,
                lng: map.settings.defaultLocation.lng,
                zoom: map.settings.zoom
            });
            map.bindFunctions();
            map.settings.afterInit();
        });
    },

    /**
     *
     * @param overrides
     */
    options: function (overrides) {
        this.settings = $.extend({}, this.settings, overrides);
    },

    /**
     *
     * @param url
     * @param callback
     * @param data
     */
    ajax: function (url, callback, data) {
        // console.log(url);

        $.ajax({
            headers: { 'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content') },
            type: "GET",
            url: url,
            data: data,
            datatype: 'xml',
            success: function (return_data) {
                callback(return_data);
            },
            error: function () {
                callback(null);
            }
        });
    },

    /**
     *
     * @param name
     * @param address
     * @param postcode
     * @param telephone
     * @param website
     * @param email
     * @param distance
     * @returns {string}
     */
    createInfoWindow: function (name, address, postcode, telephone, website, email, distance) {
        var html = '<div class="map-marker-popup"><h3><a href="{{website}}" target="_blank">{{name}}</a></h3><span class="posted">{{distance}} Miles away</span><br><br>{{address}}<br/>{{postcode}}<br/><br/>';

        if (website == null || website.length == 0) {
            var website_link = 'http://www.google.co.uk/?gws_rd=ssl#q=' + name;
        } // Add a google search to the web address
        if (website != null && website.length > 0 && !website.includes('http://')) {
            website = 'http://' + website
        } // Adding http to the web address, not sure if this will cause issues with https

        html = html.replace("{{website}}", website);
        html = html.replace('{{name}}', name);
        html = html.replace('{{distance}}', distance.toFixed(1));
        html = html.replace('{{address}}', address);
        html = html.replace('{{postcode}}', postcode);

        if (telephone != null) {
            html += telephone + '<br>';
        }
        if (email != null) {
            html += '<a href="mailto:' + email + '" target="_blank">' + email + '</a><br>';
        }
        if (website != null) {
            html += '<a href="' + website + '" target="_blank">' + website + '</a>';
        }

        html += '<br></div>';

        return html;
    },

    /**
     *
     * @param id
     * @param name
     * @param distance
     * @param telephone
     * @param website
     * @param email
     * @returns {Element}
     */
    createSidebarEntry: function (id, name, distance, telephone, website, email) {
        var html = '<div class="dealer-blocks col-xs-12 col-sm-4 col-md-3"><div>';
        html += '<a class="jump-marker" data-marker="' + id + '">';
        html += '<h4>' + name + '</h4>';
        html += '<p><em>' + distance.toFixed(1) + ' miles away</em></p>';

        if (telephone != null && telephone.length) {
            html += '<p>Tel ' + telephone + '</p>';
        }
        html += '</a>';
        html += '<div class="dealer-icons">';
        if (email != null && email.length) {
            html += '<a href="mailto:' + email + '" target="_blank" class="tip-email" title="Email this stockist"><i class="fa fa-envelope-o fa-lg"></i></a>';
        }
        if (telephone != null && telephone.length) {
            html += '<a href="tel:' + telephone + '" target="_blank" class="tip-telephone" title="Call this stockist"><i class="fa fa-mobile-phone fa-lg"></i></a>';
        }
        if (website != null && website.length) {
            html += '<a href="http://' + website + '" target="_blank" class="tip-website" title="Visit stockists website"><i class="fa fa-globe fa-lg"></i></a>';
        }
        html += '</div></div></div>';

        return html;
    },

    /**
     *
     * @returns {boolean}
     */
    isInitialized: function () {
        return this.initialized;
    },

    /**
     *
     * @returns {*}
     */
    getSearchByInput: function () {
        if (!this.isInitialized()) {
            console.log('Search attempted during script loading...');
            return false;
        }

        if ($(this.settings.search_id).length > 0) {
            return $(this.settings.search_id).val();
        } else {
            throw 'No search box found';
            return false;
        }
    },

    showAlertMessage: function (message){
        if ($(map.settings.alert_id).length > 0) {
            $(map.settings.alert_id).html( message ).slideDown();
        }
    },

    hideAlertMessage: function (){
        if ($(map.settings.alert_id).length > 0) {
            $(map.settings.alert_id).html('').slideUp();
        }
    },

    /**
     * Search for dealers using search box
     */
    searchForDealers: function (local) {
        if (!this.isInitialized()) {
            console.log('Dealer Search attempted during script loading...');
        }
        // console.log(local);
        var search = $(this.settings.search_id).val();

        if (!search.length) {
            search = 'CV13 6JX'
        }
        if (local === 'local') {
            search += " uk";
        }

        // Get location for search input
        GMaps.geocode({
            'address': search,
            callback: function (results, status) {
                if (status == 'OK') {
                    var lat = results[0].geometry.location.lat();
                    var lng = results[0].geometry.location.lng();
                    if (lat && lng) {
                        map.el.removeMarkers();
                        map.el.setCenter(lat, lng);
                        map.settings.defaultLocation = {lat: lat, lng: lng};
                        map.searchLocationsNear(map.settings.defaultLocation);
                    }
                    map.hideAlertMessage();
                } else {
                    map.showAlertMessage('Sorry, I do not know where that is!');
                }
            }
        });
    },

    /**
     *
     * @param center
     * @returns {boolean}
     */
    searchLocationsNear: function (center) {
        if (!this.isInitialized()) {
            console.log('Location Search attempted during script loading...');
            return false;
        }
        var icon = this.settings.customIcons['home'] || {};
        this.addMarker({
            lat: center.lat,
            lng: center.lng,
            icon: icon.icon,
            title: 'Current Location'
        });

        var $listing = $(this.settings.listing_id);

        $listing.html('<i class="fa fa-circle-o-notch fa-spin fa-3x" aria-hidden="true"></i>');

        this.ajax(this.settings.base_url + center.lat + '/' + center.lng + '/' + this.settings.brand, function (data) {
            $listing.html('');
            // var element = "<xml version='1.0'>"+data+"</xml>";

            var xmlDoc = $.parseXML(data);
            var $xml = $( xmlDoc );

            var markers = $xml.find("marker");

            // console.log(markers);
            // console.log(markers.length);
            // console.log('boink');

            for (var i = 0; i < markers.length; i++) {
                // console.log('boink');
                var name = markers[i].getAttribute('name');
                var address = markers[i].getAttribute('address');
                var postcode = markers[i].getAttribute('postcode');
                var telephone = markers[i].getAttribute('telephone');
                var website = markers[i].getAttribute('website');
                var email = markers[i].getAttribute('email');
                // var typ = markers[i].getAttribute('typ');
                var distance = parseFloat(markers[i].getAttribute('distance'));
                var lat = parseFloat(markers[i].getAttribute('lat'));
                var lng = parseFloat(markers[i].getAttribute('lng'));

                // console.log(lat+ ' / '+lng);

                // console.log('distance '+ distance);

                var sidebarEntry = map.createSidebarEntry(i, name, distance, telephone, website, email);

                $listing.append(sidebarEntry);

                map.mapMarkers[i] = {
                    position: new google.maps.LatLng(lat, lng),
                    lat: lat,
                    lng: lng,
                    icon: map.settings.customIcons['marker'].icon,
                    infoWindow: {
                        content: map.createInfoWindow(name, address, postcode, telephone, website, email, distance)
                    },
                    click: function(e) {
                        map.el.panTo(e.position);
                    }
                    // map: this.el,
                };

                map.addMarker(map.mapMarkers[i]);
            }
        });
    },

    addMarker: function (marker) {
        this.el.addMarker(marker);
        // var marker2 = new google.maps.Marker(marker);
    },

    panToMarker: function(id) {
        var marker = this.el.markers[id];
        this.closeInfoWindows();
        (marker.infoWindow).open(this.el, marker);
        this.el.panTo(marker.position);

        // (this.el.infoWindow).close();
    },

    closeInfoWindows: function() {
      for (var i = 1; i < this.el.markers.length; i++) {
          // var marker = this.el.markers[i];
          // console.log(marker.infoWindow);
          this.el.markers[i].infoWindow.close();
      }
    },

    scrollToMap: function () {
        // var target = $(this.settings.map_id);
        // var headerHeight = 0;//$(".navbar").height(); // because of fixed height header.
        // var scrollToPosition = $(target).offset().top - headerHeight;
        $('html,body').animate({scrollTop: this.settings.map_top}, 'fast');
    },

    bindFunctions: function() {
        $(this.settings.listing_id).on('click', '.jump-marker', function () {
            var id = $(this).data('marker');
            map.panToMarker(id+1);
            map.scrollToMap();
            // map.scrollToAnchor('top');
        });
        $(this.settings.search_id).keydown(function (e) {
            // e.preventDefault();
            map.processKey(event);
        });
        $(this.settings.search_button_id).click(function () {
            map.searchForDealers('local');
        });
    },

    /**
     *
     * @param callBack
     * @returns {boolean}
     */
    loadScripts: function (callBack) {
        if (this.isInitialized()) {
            throw 'Trying to load scripts that are already loaded.';
            return false;
        }
        // $.getScript("https://maps.google.com/maps/api/js?key=AIzaSyAXd33L6isnpkRPNefnOw7fnMeGU7BLzXQ")
        //     .done(function () {
        //         console.log("Google API loaded!");
        //         $.getScript("https://cdnjs.cloudflare.com/ajax/libs/gmaps.js/0.4.25/gmaps.min.js")
        //             .done(function () {
        //                 console.log("Gmaps API loaded!");
        //                 map.initialized = true;
        //                 callBack();
        //             })
        //             .fail(function () {
        //                 console.log("GMaps Scripts failed to Load (Triggered ajaxError handler).");
        //             })
        //     })
        //     .fail(function () {
        //         console.log("Google Maps Scripts failed to Load (Triggered ajaxError handler).");
        //     });
        this.initialized = true;
        callBack();
    },

    /**
     *
     * @param e
     * @returns {boolean}
     */
    processKey: function (e) {
        if (null == e)
            e = window.event;
        if (e.keyCode == 13) {
            document.getElementById("go-button").click();
            return false;
        }
    }
};


//
// if (search !== false && search.length > 0) {
//     map.searchForDealers( search );
// } else {
//     map.searchLocationsNear(); // Load dealer markers
// }
